import React, { useState, useEffect } from "react";
import Link from "next/link";
import styles from "./home.module.scss";
import secureLocalStorage from "react-secure-storage";
import BtnCircleArrow from '../public/icons/btn-circle-arrow.svg';
import Header from "@/components/header";

export default function Home() {

    const [pageLoader, setPageLoader] = useState(true);
    const [data, setData] = useState({});
    const [langData, setLangData] = useState(null);
    const [header, setHeader] = useState({});
    const [pageOverlay, setPageOverlay] = useState(true);
    const [pageOverlayEnd, setPageOverlayEnd] = useState(false);
    const [landingReady, setLandingReady] = useState(false);
    const [pageOverlayStart, setPageOverlayStarty] = useState(false);

    const gtagCall = (title) => {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }
        window.dataLayer.push({
            event: 'pageview',
            page: window.location.pathname,  // Page path
            title: title,  // Page title
        });
    };

    const dataCommon = () => {
        (async () => {
            const lang = secureLocalStorage.getItem("lang");
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/common?populate=*&locale=${lang}`).then((res) => res.json());
                setData(data.data);
                setPageLoader(false);
                setHeader({
                    logo: data.logo,
                    langMenu: data.langMenu,
                    menu: data.menu
                });

                setPageOverlayStarty(true)
                secureLocalStorage.setItem("pathname", "");
            } catch (err) {
                console.log(err);
                secureLocalStorage.setItem("pathname", "");
            }
        })();
    };

    const dataLang = () => {
        (async () => {
            try {
                const data = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/i18n/locales`).then((res) => res.json());
                setLangData(data);
            } catch (err) {
                console.log(err);
            }
        })();
    };

    const closeOverlay = () => {
        setPageOverlayEnd(true);
        setTimeout(() => {
            setPageOverlay(false);
            setLandingReady(true);
        }, 1600);
    };

    useEffect(() => {
    if (data && data?.head?.title) {
        gtagCall(data.head?.title); 
    }
    }, [data]); 

    useEffect(() => {
        dataLang();
        dataCommon();
    }, []);

    return (
        <>
            {pageLoader && <div className="pageLoader">
                <img src="/loader.png" alt="loader" />
            </div>}
            {pageOverlay && <div className={`${styles.loading} ${pageOverlayStart && styles.loadingStart} ${pageOverlayEnd && styles.loadingend}`}>
                <div className="maxContainer">
                    {data?.brandLogo && <figure className={styles.loadingTopLogo}><i></i>
                        <img src={data?.brandLogo?.url} alt={data?.brandLogo?.alternativeText} />
                    </figure>}
                    <Header data={header} className="landing-header" langData={langData} />
                    {data?.logo && <figure className={styles.loadinglogo}>
                        <img src={data?.logo?.url} alt={data?.logo?.alternativeText} />
                    </figure>}

                    {data?.cookieContent && <div className={styles.loadingContent}>
                        <div dangerouslySetInnerHTML={{ __html: data?.cookieContent }}></div>
                        <button onClick={closeOverlay} className={styles.enterBtn}>{data?.btnLabel} <BtnCircleArrow /></button>
                    </div>}

                    <div className={styles.loadingLogos}>
                        <label>{data?.poweredByLabel}</label>

                        {data?.poweredByImages && data?.poweredByImages?.map((img, index) => (
                            <figure key={index}>
                                <img src={img.url} alt={img.alternativeText} />
                            </figure>
                        ))}
                    </div>

                    <div className={styles.loadingFooter} dangerouslySetInnerHTML={{ __html: data?.loading?.cookiesContent }} />
                </div>
            </div>}
            <div className={`${styles.landing} ${landingReady && styles.landingReady}`}>
                <div className="container">
                    <h1 className={styles.logo}>
                        <span><img src={data?.logoMini?.url} alt={data?.logoMini?.alternativeText} /></span>
                    </h1>
                    <div className={styles.content}>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                                __html: data?.homePageDesc,
                            }}
                        ></div>
                    </div>
                    <nav>
                        {data?.menu?.map((link, index) => (
                            <Link key={index} href={`/${link.url.replace(/^\/+/, '')}`} style={{ '--target-color': link.theme || '#F3D682' }}>
                                <span>0{index + 1} - <br />{link.text}</span>
                                <BtnCircleArrow />
                            </Link>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    );
}
